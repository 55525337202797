<!-- Finder particular de Funerarias Sucursales -->

<template>
  <div class="funes_pres_import" v-if="schema">
    <v-sheet :elevation="4">
      <!-- Contenido -->
      <div class="contenedor">
        <!-- Filtro de Busqueda -->
        <div class="conflex" style="width:80%">
        
            <v-select style="flex: 0 0 100px"
              v-bind="$select"
              v-model="schema.ctrls.ano.value"
              :items="itemsAno"
              :label="schema.ctrls.ano.label"
              item-value="ano"
              item-text="label"
              disabled
              @change="get_records('ini')"
            >
            </v-select>
          
            <v-select 
              v-bind="$select"
              v-model="schema.ctrls.descrip.value"
              :items="filtroPresupuestos"
              label="Tipo presupuesto"
              item-value="id"
              item-text="label"
              @change="get_records('ini')"
            ></v-select>

          
            <v-select 
              v-bind="$select"
              v-model="schema.ctrls.cia_id.value"
              :items="itemsCia"
              :label="schema.ctrls.cia_id.label"
              item-value="id"
              item-text="label"
              @change="get_records('ini')"
            >
            </v-select>
    
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot campos grid menos el de acciones -->
          <template v-slot:fields_grid="{ Sitem, Sheader }">
            {{ get_fieldGrid(Sitem, Sheader) }}
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
import plugs from "@/common/general_plugs";
const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");

export default {
  mixins: [mixinFinder],
  components: { base_Fgrid},
  props: {
    padre: { type: String, default: "" },
    id: { type: [Number, String], default: 0 },
    // componenteTipo: { type: String, default: "FM" },
    disparoFinder: { type: Boolean, default: false },
    Entorno: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.F));
      }
    }
  },

  data() {
    return {
      schema: null,
      api: "funes_pres_F",
      stName: "stFfunes_pres_F",
    //   component_M: null,
      modal: true,
      accion: "",
      accionRow: {},
      disparo: false,

      headers: [],
      ST_tipo_presup: null,
      itemsAno: null,
      itemsCia: null,
      itemsDescripcion: null
    };
  },

  methods: {
    ini_data() {
      console.log("DEV " + this.api + " ********************** (ini_data)");

      // pendiente: por que aqui y no en la base??????
      // guardo Schema del store en variable local
      this.schema = this.$store.state[this.stName].schema;
      // obtengo items tipo presupuestos
      this.ST_tipo_presup = this.$store.state.datos_iniciales.tipo_presup;
      this.filtroPresupuestos=this.$store.state.datos_iniciales.filtroPresupuestos;
      // configuro Entorno
      this.Entorno.grid.autoload = true;
      this.Entorno.header.header = false;

      this.headers = {
        header: [
          { text: "AÑO", value: "ano" },
          { text: "CIA", value: "xcia" },
          { text: "TIPO", value: "id_presciac" },
          { text: "MUNICIPIO", value: "atlas" },
          { text: "PFT", value: "preferente", style:"color:green;font-weight: bold"},
          { text: "DESCRIPCIÓN", value: "descrip" },
          { text: "P.DIRECTO", value: "sup_pd" },
           { text: "IMPORTE", value: "imp" },
          { text: "ACCIONES", value: "acciones", sortable: false, width: "auto" }
        ]
      };
      this.itemsAno = [
        { id: "", ano: "", label: "TODOS" },
        { id: "1", ano: 2020, label: "2020" },
        { id: "2", ano: 2021, label: "2021" },
        { id: "3", ano: 2022, label: "2022" }
      ];
      this.itemsCia = [
        { id: "", label: "TODAS LAS CIAS" },
        { id: "83", label: "MAPFRE" },
        { id: "50165", label: "KUTXABANK" },
       // { id: "50508", label: "KUTXABANK" }
      ];
  

      // Inicializo cabeceras
      this.Entorno.grid.headers = this.set_headers();

    //   // guardo nombre componente de Mto
    //   if (this.componenteTipo.indexOf("M") >= 0)
    //     this.component_M = "funes_pres_M";
     
    // 
    },
    //
    /* ini_schemaCtrls() {
      this.schema.ctrls.ano.value = new Date().getFullYear();
    }, */
    ini_schemaCtrls() {
      this.schema.ctrls.ano.value = new Date().getFullYear();

      //Inicializar filtro si se envia para servicios de expedientes
       console.log("filtro:",this.filtroImportServicio)
       if (this.filtroImportServicio) {
          this.schema.ctrls.cia_id.value=this.filtroImportServicio.cia_id?this.filtroImportServicio.cia_id:''
          this.disabledCia=true;
          //añadida para que ctd pueda importar de cualquier año
          this.disabledAno=this.rol=='gestion'?false:true;
          //this.disabledAno=true;

          //nueva linea para añadir año a importacion
          if (this.filtroImportServicio.fhhr) this.schema.ctrls.ano.value=this.filtroImportServicio.fhhr.substr(0,4);
       }
      console.log("filtro:",this.schema.ctrls.cia_id.value)
    },

    //
    // row_dblclick(evt) {
    //   if (this.is_padreEdit) {
    //     this.$root.$alert.open("Cancele o Guarde para acceder", "info");
    //     return;
    //   }
    //   this.reactividad_M({
    //     accion: this.Entorno.grid.row.dblclick,
    //     row: evt.row
    //   });
    // },

    // Campos personalizados GRID
    //
    get_fieldGrid(item, header) {
      switch (header.value) {
        case "dif":
          return this.get_dif(item);

        case "id_presciac":
          return this.get_presciac(item);

        case "preferente":
          return item["preferente"] === "1" ? "P" : "";

        default:
          return item[header.value];
      }
    },

    //
    //pendiente: aplicar el mismo que en el mx
    get_dif(item) {
      // Devuelvo % de la diferencia entre importe e importe año anterior
      let imp_ant = Number(item.imp_ant);
      let imp = Number(item.imp);
      if (imp <= 0 || imp_ant <= 0) return "";
      return parseFloat(((imp - imp_ant) / imp_ant) * 100).toFixed(2);
    },

    
    // pendiente:
    // lo deberia de devolver la sql con un join en la carga de registros
    //  Devuelvo el nombre del tipo de presupuesto
    get_presciac(item) {
      const result = this.ST_tipo_presup.find(
        elem => elem.id === item.id_presciac
      ).name;
      return result == undefined ? "" : result;
    },
    // recargar finder
    reload() {
      //this.get_records(this.Entorno.grid.autoload? 'ini' : '');
    }
  },
};

</script>
